var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "layout-404"
  }, [_vm._v(" 404 ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }