<template>
  <div class="layout-404">
    404
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped>
.layout-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 64px;
  font-weight: bold;
  color: #1da4f4;
}

</style>
